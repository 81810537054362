


// export const environment = {
//     production: false,
//     env: "localhost",
//     domain: "http://localhost:1234/",
//     //apiUrl: "http://localhost:53673/api/v1/",
//     apiUrl: "https://lmsdvapi.davinchi.es/api/v1/",
//     apikey: "7aa9d2bd-22ca-47b9-b5ed-a055add86298",
//     pathResources:"http://127.0.0.1:5501/",
//     idGroup: 6,
//     Lang: "es",
// };


//---------------------------------------------------------------------------
//producción
//---------------------------------------------------------------------------

export const environment = {
    production: false,
    env: "pre",
    domain: "http://escaperoom.nmformacion.com/",
    apiUrl: "https://lmsdvapi.davinchi.es/api/v1/",
    apikey: "7aa9d2bd-22ca-47b9-b5ed-a055add86298",
    pathResources: "http://escaperoom.nmformacion.com/resources/",
    idGroup: 13,
    Lang: "es",
};

//----------------------------------------------------------------------------
