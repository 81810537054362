import $, { data } from "jquery";
import { config } from "../config";
import { environment } from "../environments/environment";
import * as session from "../services/session";


// /**
//  * Método para hacer el login
//  */
// export const login = (loginData) => {

//     var defer = $.Deferred();

//     var _loginData = {
//         "username": loginData.user,
//         "password": loginData.password
//     };

//     $.ajax({
//         type: "POST",
//         url: environment.apiUrl + "/login",
//         dataType: 'json',
//         data: _loginData,
//         success: function (dataDB) {

//             if (dataDB.error != null) {
//                 $('#cargando').hide();
//                 $('#mensaje').html("<div>" + dataDB.error.msn + "</div>");
//                 defer.reject(dataDB.error);
//             } else {
//                 session.setDataUser(dataDB.result);

//                 defer.resolve();
//             }
//         }
//     });

//     return defer.promise();
// };

// /**
//  * Envía a la base de datos la última página alcanzada
//  */
// export const sendTracking = (room) => {
//     var defer = $.Deferred();
//     var track = {
//         "idUser": 0,
//         "idAttribute": config.idAttributeTag,
//         "value": room
//     };

//     $.ajax({
//         type: "POST",
//         url: environment.apiUrl + "track",
//         dataType: 'json',
//         headers: {
//             "Authorization": "Bearer " + session.getToken()
//         },
//         data: track,
//         success: function (dataDB) {
//             if (dataDB.error != null) {
//                 $('#mensaje').html(dataDB.error.msn);
//                 $('#mensaje').show();
//                 defer.reject(dataDB.error);
//             } else {
//                 session.setCurrentRoomNumber(dataDB.result);
//                 defer.resolve();
//             }
//         }
//     });
//     return defer.promise();
// };

// /**
//  * Obtiene de la base de datos la última página alcanzada
//  */
// export const getTracking = () => {
//     var defer = $.Deferred();
//     $.ajax({
//         type: "GET",
//         url: environment.apiUrl + "/track/" + config.idAttributeTag,
//         dataType: 'json',
//         headers: {
//             "Authorization": "Bearer " + session.getToken()
//         },
//         success: function (dataDB) {
//             if (dataDB.error != null) {
//                 $('#mensaje').html(dataDB.error.msn);
//                 $('#mensaje').show();
//                 defer.reject(dataDB.error);
//             } else {
//                 session.setCurrentRoomNumber(dataDB.result);
//                 defer.resolve();
//             }
//         }
//     });
//     return defer.promise();
// };

/**
 * Registra el usuario en la base de datos con los datos que vienen del formularo
 */
// export const registerUser = (userForm) => {
//     var defer = $.Deferred();
//     var user = {
//         "idUser": 0,
//         "username": userForm.email,
//         "name": userForm.name,
//         "lastname": " ",
//         "idGroup": environment.idGroup,
//         "password": userForm.password,
//         "roles": [{
//             "idRol": 2,
//             "rol": "Alumno"
//         }],
//         "attributes": [{
//             "attribute": "centro",
//             "value": userForm.centro
//         }]
//     };

//     $.ajax({
//         type: "POST",
//         url: environment.apiUrl + "/users?activation=true",
//         dataType: 'json',
//         headers: {
//             "auth-license-finsi": environment.apikey
//         },
//         data: user,
//         success: function (dataDB) {

//             if (dataDB.error != null) {
//                 console.log(dataDB.error.code);
//                 defer.reject(dataDB.error);


//             } else {
//                 session.setDataUser(dataDB.result);
//                 defer.resolve();
//             }


//         }
//     });
//     return defer.promise();
// };



