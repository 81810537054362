import $ from "jquery";
import { environment } from "../environments/environment";
import { config } from "../config";
import * as session from "../services/session";
import * as dataServices from "../services/data.js";


export const c = config;
export const pageStart = environment.domain + config.pageContent;


export function resultadoOK() {
    $('#sr-next').show();
}

export function normalize(texto) {
    texto = texto.trim();
    texto = texto.toUpperCase();
    texto = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    console.log(texto);
    return texto;
    
}






