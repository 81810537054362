import $ from "jquery";
import { config } from "./config.ts";
import * as environment from "./environments/environment.ts";
import * as session from "./services/session.ts";
import * as dataServices from "./services/data.js";
import { environment } from "./environments/environment";
import * as app from "./base/app";


app.resultadoOK();


const roomName = 0;
console.log(`Estás en room ${roomName}`);


$('title').html(config.title);
$('.js-login').hide();
$('.js-mensaje').hide();
$('.js-aqui-text-no').hide();




$('#login').on('click', () => {

    $form = $("#login-form");
    user = getFormData($form);

    //Controlamos que los dos campos estén completos
    if (user.user != '' && user.password != '') {
        $('#cargando').fadeIn();

        $.when(dataServices.login(user)).then(
            () => {
                $.when(dataServices.getTracking()).then(() => {
                    window.location.href = "/pages/r" + session.currentRoomNumber() + "/room.html";
                    //$('#cargando').hide();
                });
            },
            (error) => {
                $('#mensaje').html("<div><img width='30' src='../../assets/img/warning.png'/><br>" + error.msn + "<br><small>Haz clic para cerrar mensaje.</small></div>");
                $('#mensaje').show();
            }
        );
    }
});

//mostrar login
$('.js-aqui').on('click', () => {
    $('.js-login').show();
    $('.js-alta').hide();
    $('.js-aqui-text').hide();
    $('.js-aqui-text-no').show();

});

//mostrar registro
$('.js-aqui-no').on('click', () => {
    $('.js-login').hide();
    $('.js-alta').show();
    $('.js-aqui-text').show();
    $('.js-aqui-text-no').hide();

});

$('#mensaje').on('click', () => {
    $('#mensaje').hide();
});

$('.mensaje--login').on('click', () => {
    console.log('click');
    $('#mensaje').hide();
    $('.mensaje--login').hide();
    $('.js-alta').hide();
    $('.js-login').show();
    $('.js-aqui-text').hide();
    $('.js-aqui-text-no').show();
});

//pop up instrucciones juego

$('.js-container__text').hide();

setTimeout(() => {
    $('.js-container__text').show();

}, 1000);

//cierra la ventana popup
$('.js-x').on("click", function () {
    $('.js-container__text').hide();

});



$('#register').on('click', () => {

    var $form = $("#register-form");
    var user = getFormData($form);


    $.when(dataServices.registerUser(user)).then(
        () => {
            $.when(dataServices.getTracking()).then(() => {
                window.location.href = "/pages/r" + session.currentRoomNumber() + "/room.html";
            });
        },
        (error) => {
            if (error.code == 6) {
                // console.log(dataDB.error);
                let mensaje = '<small>Haz clic para cerrar mensaje.</small> <br>'+ error.msn + '<br>Si ya estás inscrito o has entrado anteriormente,';
                $('#mensaje').html("<div><img width='30' src='../../assets/img/warning.png'/><br></div>" + mensaje);

                $('.mensaje--login').html("pulsa AQUÍ");
    

            } else {
                $('#mensaje').html("<div><img width='30' src='../../assets/img/warning.png'/><br>" + error.msn + "<br><small>Haz clic para cerrar mensaje.</small></div>");
            }

            $('#mensaje').show();
            $('.mensaje--login').show();


        }
    );


});



function getFormData($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n, i) {
        indexed_array[n.name] = n.value;
    });

    return indexed_array;
}