import { environment } from "../environments/environment";
import { config } from "./../config";


/**
 * Controla que el usuario esté logueado
 */
const sessionControl = ()=>{

    console.log("METODO: sessionControl")
    console.log("lastRoomNumberVisited",sessionStorage.getItem("lastRoomNumberVisited"));
    console.log("currentRoomNumber",sessionStorage.getItem("currentRoomNumber"));

    //Control del sesión
    var token = sessionStorage.getItem("token");
    var currentRoomNumber=sessionStorage.getItem("currentRoomNumber");
    var pathname = window.location.pathname;
    
     
    // //Si no existe usuario y no está en la página de inicio, lo enviamos a la página de inicio
    // if (token == null && pathname != config.pathInit) {
    //     sessionStorage.setItem("currentRoomNumber", "1" );
    //     window.location.href = `${environment.domain, config.pathInit}`;
    // } 
}


/**
 * Vacía la sesión
 */
const logout = ()=>{
 sessionStorage.clear();
}


 /**
  * 
  * @returns Devuelve el número de room actual
  */
function currentRoomNumber() {

    let currentRoomNumber="1";
    if(sessionStorage.getItem("currentRoomNumber")){
        currentRoomNumber =  sessionStorage.getItem("currentRoomNumber") || "1";
    }
    return currentRoomNumber;
}

/**
 * 
 * @returns Devuelve la room más avanzada conseguida
 */
function getLastRoomNumberVisited(){
    return sessionStorage.getItem("lastRoomNumberVisited");
}


/**
 * Se carga en sessión el número de room que viene de la base de datos
 * @param track 
 */
function setCurrentRoomNumber(track)
{
    let currentRoom="1";
    if(track.value != null){
        currentRoom=track.value
    }
    sessionStorage.setItem("currentRoomNumber", currentRoom );
    //Número de la room más avanzada que ha conseguido el usuario
    sessionStorage.setItem("lastRoomNumberVisited", currentRoom );
}

/**
 * Se cambia el número de room actual, normalmente cuando vuelves atrás
 */
function changeCurrentRoomNumber(room)
{
    sessionStorage.setItem("currentRoomNumber", room );
}

function getLastPage() {
    let lastPagePath= `${environment.domain, config.pathInit}`;
    let roomPath='/pages/r2/room.html';
    lastPagePath=`${environment.domain, roomPath}`;

    return lastPagePath;
}
 
/**
 * Guarda el token en sesion, procedente del login
 */
//  const setDataUser = (user)  =>{
//     sessionStorage.setItem("token", user.token );
//  }
 
/**
 * 
 * @returns Devuelve el token cargado en memoria
 */ 
// const getToken = ()  => sessionStorage.getItem("token");

export { setCurrentRoomNumber, changeCurrentRoomNumber, currentRoomNumber, getLastRoomNumberVisited, logout, sessionControl};

